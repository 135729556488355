
          @import "@assets/styles/_variables.scss";
        
@import "_typography.scss";
@import "_variables.scss";

* {
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Open Sans", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}